<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card @click.stop="dialog = true" class="pl-10 pr-6 py-6" style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0" :color="index % 2 ? '#f6f6f6' : ''">
        <v-layout row wrap class="align-center justify-center">
          <v-flex md1>
            <span class="mb-0 table-input">{{ index }}</span>
          </v-flex>
          <!-- <v-flex md2>
            <span class="mb-0 table-input">{{ approvedBy }}</span>
          </v-flex> -->

          <v-flex md2>
            <div class="d-flex">
              <span class="mb-0 table-input">{{ amount }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex">
              <span class="mb-0 table-input">{{ currency }}</span>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="mb-0 table-input text-truncate" style="max-width: 200px;">
                    {{ paymentRef }}
                  </span>
                </template>
                <span>{{ paymentRef }}</span>
              </v-tooltip>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex">
              <v-icon small :color="statusIcon" v-if="status">
                mdi-circle-medium
              </v-icon>
              <span class="table-input">{{ status }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="mb-0 table-input text-truncate">
                    {{ payee }}
                  </span>
                </template>
                <span>{{ payee }}</span>
              </v-tooltip>
            </div>
          </v-flex>
          <v-flex md1>
            <div>
              <span class="mb-0 table-input">{{ date }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center justify-center">
              <v-hover v-slot="{ hover }">
                <v-btn @click="$emit('openDetails', id)" :style="{
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }" exact-path depressed outlined dark small color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded" style="color: var(--v-primary-base)">
                  Details
                </v-btn>
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    approvedBy: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    currency: {
      type: String,
    },
    paymentRef: {
      type: String,
    },
    payee: {
      type: String,
    },
    date: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      //,
    };
  },
  computed: {
    statusIcon() {
      if (this.status === "unpaid") {
        return "#FF6A6A";
      } else if (this.status === "pending") {
        return "#F7CA40";
      } else return "#2BD5AE";
    },
  },

  methods: {
    //
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

.table-input {
  font-family: "inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}
</style>
